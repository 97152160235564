<template>
    <div class="idxBox column">
        <div class="titleBtnBox" v-if="types">
            <SwiperImage1 @chooseThisTitle="chooseThisTitle" :images="types" type="党建" />
        </div>
        <div class="div80"></div>
        <el-scrollbar class="grow hid">
            <div class="timeLineBox" v-infinite-scroll="getList" :infinite-scroll-immediate="false"
                :infinite-scroll-disabled="current > last_page">
                <el-timeline>
                    <el-timeline-item v-for="(item, idx) in  newList" :key="item + idx">
                        <div class="detailLineContent column">
                            <div class="divFlex">
                                <div class="divFlex alignCenter flexCenter">
                                    <div class="divFlex alignCenter">
                                        <div class="divFlex flexCenter detailTime">
                                            {{ item.releaseTime.split(' ')[0] }}
                                        </div>
                                        <div class="divFlex flexCenter alignCenter photoNumBox">
                                            <img style="width:0.3rem;" src="@/assets/img/327583.png" alt="">
                                            <span class="photoText">{{
                                                item.photoList.length }}图</span>
                                        </div>
                                    </div>

                                </div>

                                <div class="divFlex detailBtn flexCenter alignCenter" @click="seeThisDetail(item)">
                                    详情
                                </div>
                            </div>
                            <div class="detailTitle">{{ item.title || '--' }}</div>

                            <div class="allImgBox" v-if="item.photoList && item.photoList.length > 0">
                                <SwiperImage1  :images="item.photoList" />
                            </div>
                            <div class="div40"></div>
                            <div class="div40"></div>
                            <div class="div40"></div>
                        </div>
                    </el-timeline-item>
                </el-timeline>
            </div>
        </el-scrollbar>
    </div>
</template>
    
<script>
import {
    bglist,
    getNewsType,
    getNewDetail
} from "@/api";
import { SwiperImage1 } from "@/components";
export default {
    components: {
        SwiperImage1
    },
    data() {
        return {
            newList: [],
            types: false,
            type: "",
            current: 1,
            last_page: 10,
        }
    },
    computed: {

    },
    watch: {

    },
    mounted() {
        this.getType();
        this.getList()
    },
    methods: {
        getType() {
            getNewsType().then(res => {
                let obj = res.data;
                obj.unshift({ dictValue: '全部', dictKey: '' })
                this.types = obj;
            })
        },
        getList() {
            let obj = { type: this.type, current: this.current++, size: 20 }
            bglist(obj).then(res => {
                let { pages, records } = res.data
                this.last_page = pages
                this.newList = [...this.newList, ...records];
            })
        },
        seeThisDetail(val) {
            // console.log(val)
            // this.$router.push('/ActionDetail')
            this.$router.push({
                path: '/ActionDetail',
                query: {
                    id: val.id
                }
            })
        },
        chooseThisTitle(val) {
            this.type = val.dictKey;
            this.newList = [];
            this.current = 1;
            this.last_page = 10;
            this.getList()
        }
    },
};
</script>
<style lang="scss" scoped>
.titleBtnBox {
    width: 100%;
    height: 1.4rem;
    overflow: hidden;
    flex-shrink: 0;
}

.timeLineBox {
    height: 100%;

    .detailLineContent {
        padding-left: 0.4rem;
        box-sizing: border-box;
        margin-top: 0.05rem;

        .detailTime {
            width: 2.8rem;
            height: 0.6rem;
            line-height: 0.6rem;
            font-size: 0.38rem;
            font-family: Source Han Sans CN;
            font-weight: bold;
            color: #6F4F2A;
            -webkit-text-stroke: 0.01rem #FFFFFF;
            text-stroke: 0.01rem #FFFFFF;
            background-image: url('../assets/img/图层5拷贝.png');
            background-position: left;
            background-size: contain;
            background-repeat: no-repeat;
        }

        .photoNumBox {
            margin-left: 0.27rem;
            box-sizing: border-box;
            padding: 0.15rem 0.23rem;
            height: 0.6rem;
            background: rgba(242, 236, 226, 0.5);
            border: 0.02rem solid rgba(155, 132, 100, 0.5);
            border-radius: 0.3rem;

            .photoText {
                margin-left: 0.05rem;
                font-size: 0.32rem;
                font-family: Source Han Sans CN;
                font-weight: 500;
                color: #9B8464;
            }
        }

        .detailBtn {
            width: 1.15rem;
            height: 0.6rem;
            background: #5E3A0F;
            border-radius: 0.1rem;
            font-size: 0.32rem;
            font-family: Source Han Sans CN;
            font-weight: bold;
            color: #FFF4DF;
            margin-left: 1.5rem;
        }

        .detailTitle {
            font-size: 0.4rem;
            font-family: Source Han Sans CN;
            font-weight: bold;
            color: #6F4F2A;
            width: 100%;
            text-overflow: hidden;
            overflow: hidden;
            white-space: nowrap;
            margin-top: 0.44rem;
        }

        .allImgBox {
            width: 100%;
            height: 3.6rem;
            background: #FFFFFF;
            border-radius: 0.1rem;
            overflow: hidden;
            position: relative;
            margin-top: 0.4rem;
        }

    }

}
</style>
    
    